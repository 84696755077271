import React, { useState } from 'react';
import './Progress.css';


function Progress(props) {

    let resultTracker = props.resultTracker;

    let progressItems = resultTracker.map((progressItem, i) =>
        <div key={i} style={progressItem.check === true ? {backgroundColor:'#D6FED8'} : {backgroundColor:'#FED6E0'} } className='progressItemCheckIndex'></div>
    );

    let resultItems = resultTracker.map((resultItem, i) => 
        <div className='resultItem' key={i} style={resultItem.check === true ? {backgroundColor:'#D6FED8'} : {backgroundColor:'#FED6E0'} }>
            <h4>Gesuchter Begriff:</h4>
            <p>{resultItem.searched}</p>
            <h4>Deine Antwort:</h4>
            <p>{resultItem.given}</p>
            <h4>Richtige Antowrt:</h4>
            <p>{resultItem.correct}</p>
        </div>
    )


    return(

        <div className='Progress'>
            <div className='progItemBox'>            
                {progressItems}
            </div>
            <div className='resultBox'>
                {resultItems}
            </div>
        </div>
    )
}

export default Progress;