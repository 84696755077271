const kapitel1 = [
    {
        'key':1,
        'searchTerm': 'ich',
        'answer': 'jag',
    }, 
    {
        'key':2,
        'searchTerm': 'du',
        'answer': 'du',
    },
   
    {
        'key':3,
        'searchTerm': 'er',
        'answer': 'han',
    },
   
    {
        'key':4,
        'searchTerm': 'sie',
        'answer': 'hon',
    },
   
    {
        'key':5,
        'searchTerm': 'wir',
        'answer': 'vi',
    },
   
    {
        'key':6,
        'searchTerm': 'ihr',
        'answer': 'ni',
    },
]

export default kapitel1;