import React, { useState, useEffect } from 'react';
import './Quizbox.css';


function Quizbox(props) {
    let resultTracker = props.resultTracker;
    let setResultTracker = props.setResultTracker;
    let currentTerm = props.currentTerm;
    let toggle = props.toggle;
    let setToggle = props.setToggle;

    const [givenAnswer, setGivenAnswer] = useState();

    function handleChange(e) {
        setGivenAnswer(e.target.value);
    }


    function handleSubmit() {
        if (givenAnswer == currentTerm.answer) {
            document.querySelector('.Quizbox').style.backgroundColor = '#D6FED8';
            setResultTracker([...resultTracker, { searched : currentTerm.searchTerm, correct : currentTerm.answer, given : givenAnswer, check : true }]);
        } else {
            document.querySelector('.Quizbox').style.backgroundColor = '#FED6E0';
            setResultTracker([...resultTracker, { searched : currentTerm.searchTerm, correct : currentTerm.answer, given : givenAnswer, check : false }]);
        };
        setToggle('closed');
    }

    return(
        <div className='Quizbox'>
            <h2>{currentTerm ? currentTerm.searchTerm : 'warte..'}</h2>
            <h3 style={toggle === 'open' ? {display:'none'} : {display:'block'}}>Richtige Antwort: <br></br><br></br>{currentTerm.answer}</h3>
            <p>Antwort:</p>
            <input className={toggle === 'open' ? 'toggleOpen': 'toggleClosed' } onChange={toggle == 'open' ? handleChange : console.log('no') } placeholder='Antwort hier eingeben'></input>
            <br></br><br></br><br></br>
            <button type="submit" className={toggle === 'open' ? 'toggleOpen checkAnswer': 'toggleClosed checkAnswer'} onClick={toggle == 'open' ? handleSubmit : console.log('no') }>abschicken </button>
        </div>
    )
}

export default Quizbox;