const kapitel2 = [
    {
        'key':1,
        'searchTerm': 'Haus',
        'answer': 'hus',
    }, 
    {
        'key':2,
        'searchTerm': 'Maus',
        'answer': 'mus',
    },
   
    {
        'key':3,
        'searchTerm': 'Fenster',
        'answer': 'fönster',
    },
   
    {
        'key':4,
        'searchTerm': 'See',
        'answer': 'sjö',
    },
   
    {
        'key':5,
        'searchTerm': 'Frosch',
        'answer': 'groda',
    },
   
    {
        'key':6,
        'searchTerm': 'Gurke',
        'answer': 'gurka',
    },
]

export default kapitel2;